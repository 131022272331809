/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').filter('booleanFilter', function() {
    return function(input, replace_true, replace_false) {
        return input?replace_true:replace_false;
    };
});

