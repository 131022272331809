/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .filter('parseSiteName', function () {
        return function(input, type) {
            if(input) {
            	if(type === 'icon') {
	                var parts = input.split('.');
	                return parts[0].toLowerCase();
	            } else if(type === 'shop') {
	            	switch(input) {
	            		case 'Shopify.com':
	            			return 'Shopify App Store';
	            		case 'Magento.com':
	            			return 'Magento Connect';
                  case 'Prestashop':
                    return 'Prestashop';
	            	}
	            } else if(type === 'url') {
	            	switch(input) {
	            		case 'Shopify.com':
	            			return 'https://apps.shopify.com/market-insights';
	            		case 'Magento.com':
	            			return 'https://www.magentocommerce.com/magento-connect/catalog/product/view/id/31470/';
                  case 'Prestashop':
                    return 'https://hivewyre.com/market-insights-next-platform-prestashop/';
	            	}
	            }
	            return input;
            }
            return input;
        };
    });

