/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp')
    .filter('censorcampaignname', ['userServ', function(userServ){
        return function(input){
            if(userServ.isSalesman()){
                input = input.split('--');
                if(input.length > 1){
                    input[0] = '.....';
                }
                input = input.join('--');
                return input;
            }
            return input;
        }
    }]);

