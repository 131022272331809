/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').filter('replaceUndefined', function() {
    return function(input, replacer) {
        return angular.isUndefined(input)?replacer:input;
    };
});

