/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').filter('daytargetsinfo', function($filter) {
    return function(input) {
        var output = '';
        var format = 'HH:mm';
        if(angular.isArray(input)){
            input.forEach(function(v, i){
                var start = v.start_hour + ':00',
                    stop = v.end_hour + ':00';
                output += v.day[0].toUpperCase() + v.day.slice(1);
                output += ' ' + start + ' - ' + stop;
                if(i < input.length - 1){
                    output += ' or ';
                }
            });
        }
        return output
    };
});

