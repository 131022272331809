/*** IMPORTS FROM imports-loader ***/


angular.module('adhesiveApp').filter('replaceNull', function() {
    return function(input, replacer) {
        var isNull = input === null || angular.isUndefined(input)
        return isNull ? replacer : input;
    };
});


