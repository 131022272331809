/*** IMPORTS FROM imports-loader ***/


'use strict';

angular.module('adhesiveApp').filter(
    'orderByLast', function() {
    return function(array, expression, /*reverse, */lastElements) {
        var reverse;

        if(reverse === undefined) {
            reverse = false;
            if(angular.isString(expression) && expression[0] === '-') {
                reverse = true;
                expression = expression.slice(1);
            }
        }

        if(!angular.isArray(lastElements)) {
            lastElements = [lastElements];
        }

        return array.sort(function(a, b) {
            var aValue = a[expression],
                bValue = b[expression],
                aIndex = lastElements.indexOf(aValue),
                bIndex = lastElements.indexOf(bValue),
                result;

            if(aIndex > -1 || bIndex > -1) {
                if(aIndex > -1 && bIndex > -1) {
                    result = aIndex - bIndex;
                }
                else if(aIndex > -1){
                    result = 1;
                }
                else {
                    result = -1;
                }
            }
            else {
                aValue = String(aValue);
                bValue = String(bValue);

                if(aValue > bValue) {
                    result = 1;
                }
                else if(aValue < bValue) {
                    result = -1;
                }
                else {
                    result = 0;
                }

                result = reverse ? -result : result;
            }

            return result;
        });
    };
});

