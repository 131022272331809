/*** IMPORTS FROM imports-loader ***/


// ssdassdsdwsdsd
angular.module('adhesiveApp')
    .filter('shownonzero', function () {
        return function (input) {
            if(Number(input) == 0){
                return '';
            }

            return input;
        };
    })
    .filter('pnumber', ['$filter', 'utils', function($filter, utils){
        return function(number, minSize, maxSize){
            number = Number(number);
            if(!number){
                return '';
            }
            var maxSizeNumber = Number(number.toFixed(maxSize));
            var precision = utils.precision(maxSizeNumber);

            if(precision > minSize && precision <= maxSize){
                return $filter('number')(number, precision);
            }
            else {
                return $filter('number')(number, minSize);
            }
        }
    }])
    .filter('naturalnumberspaces', function () {
        return function (input) {
            if(!Number(input)) {
                return '';
            }
            var str = Math.floor(input).toString();
            str = str.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
            return str;
        };
    })
    .filter('naturalnumbercomas', function () {
        return function (input) {
            if(!Number(input)) {
                return '';
            }
            var str = Math.floor(input).toString();
            str = str.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            return str;
        };
    })
    .filter('capitalize', function() {
        return function(input) {
            return input.slice(0,1).toUpperCase() + input.slice(1).toLowerCase();
        };
  });

